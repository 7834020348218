import { Decimal } from 'decimal.js'
import resolveConfig from 'tailwindcss/resolveConfig'
import { useRouter } from 'nuxt/app'
import { reformat } from '~/utils/date'
import tailwindConfig from '~~/tailwind.config'
import { EventBus } from '~/utils/eventBus'
import { calculateTotalTooltip } from '~/utils/chartJs.util'
import numberWithCommas from '~/utils/numberWithCommas'
import { DateFormats, Units } from '~/constants/general'
import toDecimalPlaces from '~/utils/chartData/toDecimalPlaces'

const fullConfig = resolveConfig(tailwindConfig)

function pseudoGoTo(event) {
  event.preventDefault()

  if (event.target.dataset.pseudoLink === undefined) return

  const router = useRouter()
  const pathInfo = { path: event.target.dataset.pseudoLink }
  router.push(pathInfo)
}

function onMouseOnChart(onMouseOnChart) {
  const tooltipEl = document.getElementById('chartjs-tooltip')

  if (!tooltipEl) {
    return
  }

  if (!onMouseOnChart) {
    tooltipEl.remove()
  }
}

function findValue(body) {
  const str = body.toString()
  const match = str.match(/\b(\d+\.?\d*|\.\d+)/)
  return match ? match[0] : null
}

function getTooltipElement(chartId, scrolled) {
  let tooltipEl = document.getElementById('chartjs-tooltip')

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.style['max-height'] = '267px'
    tooltipEl.style['overflow-y'] = 'auto'
    tooltipEl.id = 'chartjs-tooltip'
    tooltipEl.innerHTML = '<table></table>'
    const chartWrapper = document.getElementById('benchmark-chart-' + chartId) || scrolled
    chartWrapper.appendChild(tooltipEl)
    if (!EventBus.all.has('mouseonchart')) {
      EventBus.on('mouseonchart', onMouseOnChart)
    }
    tooltipEl.addEventListener('click', pseudoGoTo)
  }

  return tooltipEl
}

function handleTooltipClasses(tooltipEl, tooltipModel) {
  tooltipEl.classList.remove('above', 'below', 'no-transform')
  if (tooltipModel.tooltip.yAlign) {
    tooltipEl.classList.add(tooltipModel.tooltip.yAlign)
  } else {
    tooltipEl.classList.add('no-transform')
  }
}

function bodyLineHandle(stringBody, tooltipModel, innerHtml, body, total, i) {
  const stringNumbers = stringBody.match(/^(?:\d+(\.\d+)|\d+(\.\d+)\b|\d+(\.\d+)(?=\w))$/g)
  if (!Array.isArray(stringNumbers) || stringNumbers[2] !== '0') {
    const colors = tooltipModel.tooltip.labelColors[i]
    /* Use the default (yellow) color if the color is undefined for some reason */
    const color = colors.backgroundColor || fullConfig.theme.colors.chart.yellow
    let style = 'background:' + color + '; border-color:' + color
    style += '; border-width: 2px; margin-right: 5px;'
    const span = '<span style="' + style + '"></span>'
    if (body.length) innerHtml += '<tr><td>' + span + body + '</td></tr>'
    if (body) {
      const stringMatch = findValue(stringBody)
      total = stringMatch ? total + Number(stringMatch) : total
    }
  }
  return { innerHtml, total }
}

function customTooltip(chartId, tooltipModel, tooltipPostfix, showTotal, tooltipPosition = null) {
  const scrolled = document.getElementsByClassName('chartAreaWrapper')[0]
  const tooltipEl = getTooltipElement(chartId, scrolled)

  handleTooltipClasses(tooltipEl, tooltipModel)

  function getBody(bodyItem) {
    return bodyItem.lines
  }

  if (tooltipModel.tooltip.body) {
    const titleLines = tooltipModel.tooltip.title || []
    const bodyLines = tooltipModel.tooltip.body.map(getBody)

    let innerHtml = '<thead>'

    const tableRoot = tooltipEl.querySelector('table')

    if (bodyLines?.length > 0 && bodyLines.some((line) => findValue(line) !== '0')) {
      titleLines.forEach(function (title) {
        innerHtml +=
          '<tr><th data-testid="custom-tooltip-title" class="text-base text-left font-bold pb-2">' +
          title.replace(/,(?=\S)|:/g, ' ') + // replacing a comma without a space with a space
          '</th></tr>'
      })
      innerHtml += '</thead><tbody>'

      const { dataPoints } = tooltipModel.tooltip
      const total = calculateTotalTooltip(dataPoints)

      bodyLines.forEach(function (body, i) {
        const stringBody = body.toString().replace(/,/g, '')
        const { innerHtml: innerHtmlNew } = bodyLineHandle(
          stringBody,
          tooltipModel,
          innerHtml,
          body,
          total,
          i,
        )
        innerHtml = innerHtmlNew
      })

      if (total > 0 && bodyLines.length > 1 && showTotal) {
        const formattedTotal = toDecimalPlaces(total, tooltipPostfix, true)
        innerHtml +=
          '<tr><td data-testid="custom-tooltip-total" class="font-bold pt-2">Total: ' +
          formattedTotal +
          ' ' +
          tooltipPostfix +
          '</td></tr>'
      }
      innerHtml += '</tbody>'
      tableRoot.innerHTML = innerHtml

      const tooltipTopPosition = tooltipPosition || tooltipModel.tooltip.y

      tooltipEl.style.background = tooltipModel.tooltip.options.backgroundColor
      tooltipEl.style.color = tooltipModel.tooltip.options.bodyColor
      tooltipEl.style.borderRadius = '4px'
      tooltipEl.style.position = 'absolute'
      tooltipEl.style.minWidth = '270px'
      tooltipEl.style.width = 'auto'
      tooltipEl.style.left = -93 + tooltipModel.tooltip.caretX + 'px'
      tooltipEl.style.top = tooltipTopPosition + 'px'
      tooltipEl.style.fontFamily = tooltipModel.tooltip.options.bodyFont.family
      tooltipEl.style.fontSize = tooltipModel.tooltip.options.bodyFont.size + 'px'
      tooltipEl.style.fontStyle = tooltipModel.tooltip.options.bodyFont.style
      tooltipEl.style.padding = '5px'
      tooltipEl.style.paddingRight = '15px'
      tooltipEl.style.pointerEvents = 'auto'
    } else {
      tooltipEl.remove()
    }
  }
}

function setCrosshair(pluginCrosshairEnabled) {
  return pluginCrosshairEnabled
    ? {
        line: {
          color: fullConfig.theme.colors['p-blue']['100'],
          width: 1,
        },
        zoom: {
          enabled: false,
        },
        snap: {
          enabled: true,
        },
        sync: {
          enabled: false,
        },
      }
    : {}
}

/* Canvas Tooltip Functions START */
function createTooltipTitle(tooltipItem, horizontal, tooltipTitleIsDate, priceRange, chartId) {
  if (horizontal) return tooltipItem[0].label.split('[')[0] // Tooltip title is yScale's label for the horizontal charts
  if (tooltipTitleIsDate) return reformat(tooltipItem[0].label, DateFormats.dd_MMMM_yyyy, false)
  if (priceRange && tooltipItem.length) {
    return chartId === 'price_ranges_lithium'
      ? tooltipItem[0].label.replaceAll(',', ' ')
      : tooltipItem[0].label.replaceAll(',', ', ').replaceAll(',  ', ', ').replaceAll(', ,', ',')
  }
  if (tooltipItem.length) return tooltipItem[0].label
}

function tooltipLabelRanges(dataset, labels, tooltipPostfix) {
  const pricesLabel = labels
    .slice(0, 2)
    .map((value) => `${tooltipPostfix}${numberWithCommas(value)}`)
    .join(' - ')
  const pricesDates = labels
    .slice(2, 4)
    .map((value) => `${reformat(value, DateFormats.dd_MMMM_yyyy, false)}`)
    .join(' - ')
  const labelString = `${dataset}: ${pricesLabel}`
  return [labelString, pricesDates]
}

function createTooltipLabel({
  tooltipItem,
  tooltipPostfix,
  thousandfoldXLabel,
  thousandfoldYLabel,
  chartId,
  hasCustomTooltip,
  hasTooltipValueProp,
  showPercentageStackedBar,
}) {
  let label = tooltipItem.raw
  if (
    chartId === 'capacity-index' &&
    tooltipItem.datasetIndex === 1 &&
    tooltipItem.parsed._stacks.x[1] !== 0
  ) {
    label = tooltipItem.parsed._stacks.x[0] + tooltipItem.parsed._stacks.x[1]
  } else if (thousandfoldXLabel || thousandfoldYLabel) {
    label = label * 1000
  } else if (hasTooltipValueProp) {
    const currentYearIndex = tooltipItem.dataIndex
    label = tooltipItem.dataset.tooltipValue[currentYearIndex]
  } else if (Object.keys(label).includes('y')) {
    label = label.y
  } else if (Array.isArray(label) && label.length === 2) {
    const newValue = label[1] - label[0]
    const formattedValue = toDecimalPlaces(newValue, tooltipPostfix, true)
    return `${tooltipItem.dataset.label}: ${formattedValue} ${tooltipPostfix}`
  }
  const value = toDecimalPlaces(label, tooltipPostfix, true)
  const datasetLabel = tooltipItem.dataset.data.length > 0 ? ` ${tooltipItem.dataset.label}: ` : ''
  if (hasCustomTooltip && tooltipItem.dataset.url) {
    return `<a class="pseudo-link hover:underline inline-block cursor-pointer" href="${tooltipItem.dataset.url}" data-pseudo-link="${tooltipItem.dataset.url}">
      ${datasetLabel}${value} ${tooltipPostfix}
    </a>`
  }

  const space = tooltipPostfix !== Units.PERCENTAGE ? ' ' : ''

  if (showPercentageStackedBar) {
    const stackedBarTotalValue = Object.values(tooltipItem.parsed._stacks.y._visualValues)
      .reduce((acc, value) => acc.plus(value), new Decimal(0))
      .toNumber()

    const rawValue = tooltipItem.raw
    const percentage = (rawValue / stackedBarTotalValue) * 100
    const formattedPercentage = toDecimalPlaces(percentage, Units.PERCENTAGE, true)

    return ` ${datasetLabel}${value}${space}${tooltipPostfix} (${formattedPercentage}${Units.PERCENTAGE})`
  }

  if (value !== 0 || hasTooltipValueProp) {
    return ` ${datasetLabel}${value}${space}${tooltipPostfix}`
  }
}

function enableTooltipFooter(tooltipItem, hasTooltipValueProp) {
  const isRange = Array.isArray(tooltipItem.raw)
  const isNumberButNot0 = !isNaN(tooltipItem.raw) && tooltipItem.raw !== 0
  const isRawYNot0 = tooltipItem.raw.y && tooltipItem.raw.y !== 0
  return isRange || isNumberButNot0 || isRawYNot0 || hasTooltipValueProp
}

function createTooltipFooter(tooltipItem, showTotal, excludeLabelsToTotal, tooltipPostfix) {
  if (showTotal && tooltipItem.length > 1) {
    let tooltipItemFormmated = tooltipItem
    if (Array.isArray(excludeLabelsToTotal) && excludeLabelsToTotal.length) {
      tooltipItemFormmated = tooltipItem.filter((item) => {
        const label = item.dataset.label
        return !excludeLabelsToTotal.some((item) => label.toLowerCase().includes(item))
      })
    }

    const rawTotal = calculateTotalTooltip(tooltipItemFormmated)
    const total = toDecimalPlaces(rawTotal, tooltipPostfix, true)

    return `Total: ${total} ${tooltipPostfix}`
  }
}

/* Canvas Tooltip Functions END */

function createLegendCallback(chart) {
  const dataSets = []
  for (let i = 0; i < chart.data.datasets.length; i++) {
    dataSets.push({
      index: chart.legend.legendItems[i].datasetIndex,
      label: chart.data.datasets[i].label,
      color: chart.data.datasets[i].borderColor,
      hidden: false,
    })
  }
  return dataSets
}

/* Scales Functions START */
function yScalesAfterFit(horizontal, chartId, scaleInstance) {
  if (horizontal && chartId.search('capacity-by-tier') === -1) scaleInstance.width = 283
}

function xScalesAfterFit(horizontal, verticalXAxesTicks, scale) {
  if (!horizontal) scale.height = 70
  if (verticalXAxesTicks) scale.height = 100
}

function xScaleTicksCallback(tooltipTitleIsDate, index, instance) {
  const label = instance.getLabelForValue(index)
  if (tooltipTitleIsDate) {
    return reformat(label, DateFormats.MMM_yyyy, false)
  }
  return label
}

function xScaleMaxRotation(maxTicksLimit, verticalXAxesTicks) {
  const corner = verticalXAxesTicks ? 90 : 20
  return maxTicksLimit ? 0 : corner
}

/* Scales Functions END */

function resConditions(res, yAxisMax, pluginCrosshairEnabled, horizontal, tooltipTitleIsDate) {
  if (yAxisMax) {
    res.scales.y.max = yAxisMax
  }
  if (pluginCrosshairEnabled) {
    res.plugins.tooltip.mode = tooltipTitleIsDate ? 'nearest' : 'index'
    if (tooltipTitleIsDate) res.plugins.tooltip.axis = 'x'
    res.plugins.tooltip.intersect = false
    delete res.interaction
  }
  if (horizontal) {
    delete res.scales.y.ticks.callback
  }
}

function setLayoutPadding(hideYLabel, thermometer) {
  const left = thermometer ? 150 : 0
  let right = 0
  if (hideYLabel) right = 100
  if (thermometer) right = 150
  return {
    left,
    right,
    top: 0,
    bottom: 0,
  }
}

const getSuggestedMaxYScaleValue = (maxYScaleValue) => (maxYScaleValue > 0 ? maxYScaleValue : null)

const getYScaleMaxValue = (maxYValue) => (maxYValue > 0 ? maxYValue : null)

const getBaseChartConfig = ({
  indexAxis,
  chartId,
  hasCustomTooltip,
  title,
  showLegend,
  isStacked,
  yAxisPostfix,
  yScaleLabel,
  xScaleLabel,
  yAxisMax,
  tooltipPostfix,
  tooltipTitleIsDate,
  pluginCrosshairEnabled,
  showTotal,
  scaleTextColor,
  yScaleTextColor,
  maxXScaleValue,
  horizontal,
  verticalXAxesTicks,
  maxTicksLimit,
  gridLinesColor,
  thousandfoldXLabel,
  thousandfoldYLabel,
  hideYLabel,
  startXFromZero,
  priceRange,
  excludeLabelsToTotal,
  xAlign,
  yAlign,
  hasTooltipValueProp,
  thermometer,
  maxYScaleValue,
  maxYValue,
  showPercentageStackedBar,
  fontSizeY,
  fontSizeX,
  titleFontSize,
}) => {
  const res = {
    indexAxis,
    maintainAspectRatio: false,
    spanGaps: false,
    elements: {
      LineElement: {
        tension: 0.000001,
      },
    },
    legendCallback: createLegendCallback,
    layout: {
      padding: setLayoutPadding(hideYLabel, thermometer),
    },
    animations: horizontal ? { y: { duration: 0 } } : undefined,
    hover: {
      // required for crosshair snap to work
      intersect: false,
    },
    scales: {
      y: {
        stacked: isStacked,
        beginAtZero: true,
        max: getYScaleMaxValue(maxYValue),
        suggestedMax: getSuggestedMaxYScaleValue(maxYScaleValue),
        ticks: {
          callback(value) {
            return `${numberWithCommas(value)}${yAxisPostfix}`
          },
          color: yScaleTextColor || scaleTextColor,
          font: {
            size: fontSizeY || 14,
            weight: '400',
            family: 'Montserrat',
          },
        },
        title: {
          display: yScaleLabel.length > 0,
          color: scaleTextColor,
          font: {
            size: titleFontSize || 12,
            weight: '400',
            family: 'Montserrat',
          },
          text: yScaleLabel,
          padding: {
            bottom: 8,
          },
        },
        grid: {
          color: gridLinesColor,
          borderColor: gridLinesColor,
        },
        afterFit(scaleInstance) {
          return yScalesAfterFit(horizontal, chartId, scaleInstance)
        },
      },
      x: {
        position: horizontal ? 'top' : 'bottom',
        stacked: isStacked,
        min: startXFromZero ? 0 : undefined,
        suggestedMax: maxXScaleValue > 0 ? maxXScaleValue : null,
        ticks: {
          callback(index) {
            return xScaleTicksCallback(tooltipTitleIsDate, index, this)
          },
          autoSkip: !tooltipTitleIsDate || !priceRange,
          color: scaleTextColor,
          font: {
            size: fontSizeX || 14,
            weight: '400',
            family: 'Montserrat',
          },
          maxTicksLimit,
          maxRotation: xScaleMaxRotation(maxTicksLimit, verticalXAxesTicks),
          minRotation: verticalXAxesTicks ? 90 : 0,
          labelOffset: verticalXAxesTicks ? 2 : 0,
          padding: verticalXAxesTicks ? 10 : 0,
        },
        title: {
          display: xScaleLabel?.length > 0,
          color: fullConfig.theme.colors.white,
          font: {
            size: 16,
            weight: '400',
            family: 'Montserrat',
          },
          text: xScaleLabel,
          padding: 12,
        },
        grid: {
          color: gridLinesColor,
          borderColor: gridLinesColor,
        },
        afterFit: (scale) => {
          return xScalesAfterFit(horizontal, verticalXAxesTicks, scale)
        },
      },
    },
    interaction: {
      intersect: false,
      mode: horizontal ? 'y' : 'nearest',
      axis: horizontal ? undefined : 'x',
    },
    plugins: {
      title: {
        display: true,
        text: title,
      },
      legend: {
        display: showLegend,
        onHover: () => {
          document.getElementById(chartId).style.cursor = 'pointer'
        },
        onLeave: () => {
          document.getElementById(chartId).style.cursor = 'default'
        },
      },
      tooltip: {
        enabled: !hasCustomTooltip,
        itemSort(a, b) {
          return horizontal ? a.datasetIndex - b.datasetIndex : b.datasetIndex - a.datasetIndex
        },
        xAlign,
        yAlign,
        filter: (tooltipItem) => {
          return enableTooltipFooter(tooltipItem, hasTooltipValueProp)
        },
        // titleAlign: 'center',
        titleFont: { size: 16 },
        titleMarginBottom: 10,
        bodyFont: { size: 14 },
        bodySpacing: 8,
        footerFont: { size: 15 },
        footerMarginTop: 12,
        padding: { x: 10, y: 10 },
        position: 'nearest',
        external: hasCustomTooltip
          ? (tooltipModel) => customTooltip(chartId, tooltipModel, tooltipPostfix, showTotal)
          : null,
        callbacks: {
          title(tooltipItem) {
            return createTooltipTitle(
              tooltipItem,
              horizontal,
              tooltipTitleIsDate,
              priceRange,
              chartId,
            )
          },
          label: (tooltipItem) => {
            if (priceRange) {
              /*
                Here the tooltips for lithium range are created
              */
              return tooltipLabelRanges(tooltipItem.dataset.label, tooltipItem.raw, tooltipPostfix)
            }
            return createTooltipLabel({
              tooltipItem,
              tooltipPostfix,
              thousandfoldXLabel,
              thousandfoldYLabel,
              chartId,
              hasCustomTooltip,
              hasTooltipValueProp,
              showPercentageStackedBar,
            })
          },
          labelColor(tooltipItem) {
            if (!Array.isArray(tooltipItem.dataset.borderColor)) {
              return {
                backgroundColor: tooltipItem.dataset.borderColor, // Set the tooltip color the same as the bar
              }
            }
            return {
              backgroundColor: tooltipItem.dataset.borderColor[tooltipItem.dataIndex], // borderColor can be an array (like in Capacity Index chart)
            }
          },
          footer(tooltipItem) {
            return createTooltipFooter(tooltipItem, showTotal, excludeLabelsToTotal, tooltipPostfix)
          },
        },
      },
      crosshair: setCrosshair(pluginCrosshairEnabled),
    },
  }

  resConditions(res, yAxisMax, pluginCrosshairEnabled, horizontal, tooltipTitleIsDate)

  return res
}

const defaultChartColorPalette = Object.values(fullConfig.theme.colors.chart)

const getCrosshairOptions = (color) => ({
  line: {
    color: color || fullConfig.theme.colors['p-blue']['100'],
    width: 1,
  },
  zoom: {
    enabled: false,
  },
  snap: {
    enabled: true,
  },
  sync: {
    enabled: false,
  },
})

export { getBaseChartConfig, defaultChartColorPalette, customTooltip, getCrosshairOptions }
